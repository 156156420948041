function KeywordProcess(handler) {

    this.url = dataActive.location.marketplace + "/v1/posts/by-keywords";
    var me = this;
    var $showMore = handler.domNode.find('.show-more');
    var $pageNumber = handler.domNode.find('.page-number');
    var postByKeywordsItems = handler.domNode.find('.post-by-keywords-items');
    var input = handler.domNode.find('.keyword-input');

    if (dataActive.location.query != undefined && dataActive.location.query.keywords != undefined) {
        ajax({
            url: me.url,
            method: "post",
            data: {
                keywords: decodeURIComponent(dataActive.location.query.keywords),
            },
            success: function (data) {
                me.mapContent(data.data);
                me.loadMore(data.pages);
            },
            error: function () {

            }
        });
    }

    handler.domNode.find('.search-btn').on('click', function () {
        dataActive.pageRoute('postByKeywords', { keywords: input.val() })
    });

    this.mapContent = function (data) {
        input.val(decodeURIComponent(dataActive.location.query.keywords));
        postByKeywordsItems.empty();
        data.forEach((item, index) => {
            postByKeywordsItems.append(view('blog/post-by-keyword-item', { item: item }));
        });
    }

    this.showMore = function () {
        $showMore.hide();
        var pageNumber = $pageNumber.val($showMore.data('currentPage') + 1);
        var page = pageNumber.val();
        dataActive.hooks.register('afterAjaxError', this.afterAjaxError);
        ajax({
            url: me.url,
            method: "post",
            data: {
                keywords: dataActive.location.query.keyword,
                page: page,
            },
            success: function (data) {
                me.mapContent(data.data);
                $showMore.data('currentPage', data.pages.page);
                if ($showMore.data('pageCount') > data.pages.page) {
                    $showMore.show();
                }
            },
        })
    }

    this.loadMore = function (pages) {
        if ($showMore.data('pageCount') == undefined) {
            $showMore.click(me.showMore)
        }
        $showMore.data('pageCount', pages.pageCount);
        $showMore.data('currentPage', pages.page);
        if (pages.pageCount > pages.page) {
            $showMore.show();
        } else {
            $showMore.hide();
        }
    }
}