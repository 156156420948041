function LoginProcess(handler) {
    var me = this;
   
    this.singUp = function () {
        handler.domNode.find(".sign-up").on('click', function (e) {
            e.preventDefault();
            handler.domNode.find(".login-form").hide();
            handler.domNode.find(".add-sing-up-form").append(view("pages/register-form"));
            handler.domNode.find(".sign-in").on('click', function (e) {
                e.preventDefault();
                handler.domNode.find(".register-form").hide();
                handler.domNode.find(".login-form").show();

            });
            me.showPassword();
            var $form = $(".sing-up-form");
            var tracker = dataActive.getObject("UserRegisterForm", { form: $form });
            $form.find('[type=submit]').attr('type', 'button').on('click', function () {
                let hookAnswer = dataActive.hooks.call('beforeSubmit', { form: $form });
                if ($.isEmptyObject(hookAnswer) || $.isEmptyObject(hookAnswer.form)) {
                    return;
                }
                $form = hookAnswer.form;
                ajax(tracker.ajaxParams());
            });
            var datePickerBirthdate = handler.domNode.find('.datePickerBirthdate');
            new SpecialDate(datePickerBirthdate, { language: dataActive.location.query.lang, dateType: 'birthdate' });
        });
    }

    this.loginOptions = function () {
        ajax({
            url: dataActive.location.marketplace + '/v1/login-options',
            method: 'GET',
            success: function (data) {

                var forms = [];
                data.forEach((item) => {
                    if (item['requirement']['type'] == 'form') {
                        forms.push(item);
                        if (item['name'] == 'Local') {
                            handler.domNode.find('.sign-up-section').show();
                        }
                    } else if (item['requirement']['type'] == 'social') {
                        handler.domNode.find('.theme-login-social-separator').removeClass('d-none')
                        handler.domNode.find('.social-login-buttons').append(`<div class="col-md-6 col-md-offset-3 m-b-15"> ${item['requirement']['button']} </div>`);
                        if (item['name'] == 'Google') {
                            dataActive.$head.append(item['requirement']['items']);
                        }
                        if (item['name'] == 'Facebook') {
                            dataActive.$body.prepend(item['requirement']['items']);
                        }
                    }
                });
                handler.domNode.find('.theme-login-box-inner').prepend(view('partials/login-forms-tabs', { forms: forms }));
                forms.forEach((item) => {
                    var $form = handler.domNode.find('[data-login=' + item.name + ']');
                    var tracker = dataActive.getObject("userLoginForm", $form);
                    $form.find('input').keyup(function (e) {
                        // Enter pressed?
                        if (e.which == 13) {
                            tracker.disableButton();
                            ajax(tracker.ajaxParams());
                        }
                    });

                    $form.find('[type=submit]').attr('type', 'button').on('click', function () {
                        tracker.disableButton();
                        ajax(tracker.ajaxParams());
                    });
                }); 
                handler.domNode.find('.theme-login-form-group:has(.see-password)').css('position', 'relative');
                me.showPassword();
            },
            error: function (data) {
            }

        })
    }

    window.handleCredentialResponse = function (googleUser) {
        me.socialLogin(googleUser.credential, 'google');
    }

    window.checkLoginState = function () {
        FB.getLoginStatus(function (response) {
            statusChangeCallback(response);
        });
    }

    window.statusChangeCallback = function (response) {
        if (response.status === 'connected') {
            me.socialLogin(response.authResponse.accessToken, 'facebook', response.authResponse.userID);
        } else {
            document.getElementById('status').innerHTML = 'Please log into this webpage.';
        }
    }

    this.socialLogin = function (token, loginType, userId) {
        ajax({
            url: dataActive.location.marketplace + "/v1/login/" + loginType,
            method: "post",
            data: {
                id_token: token,
                user_id: userId,
            },
            success: function (data) {
                if (!$.isEmptyObject(data)) {
                    setStorage("loginInformation", data);
                }
                dataActive.previous();
            },
            error: function (data) {
                // lug.error("userLogin", { message: JSON.parse(data.responseText).message });
                handler.domNode.find('.social-login-buttons').after('<div class="show-error-style text-center">' + JSON.parse(data.responseText).message + '</div>');
            }
        });
    }

    this.showPassword = function() {
        handler.domNode.find(".see-password").on("click", function() {
            var $input = $(this).prev();
            $(this).toggleClass("fa-eye fa-eye-slash");
            if($(this).hasClass("fa-eye")) {
                $input.attr("type","text")
            } else {
                $input.attr("type","password");
            }
        });
    }

    this.init = function () {
        this.singUp();
        this.loginOptions();
    }


    window.userSingupSchema = function () {
        var schema = {};
        $.map(getConfig('userSignupSchema', {}), function (schemaItem, schemaIndex) {
            schema[schemaItem['label']] = schemaItem;
        });
        var labels = [];
        var elements = [];
        var order = ['Birthdate', 'Country', 'City', 'Address', 'Age'];
        for (var index in schema) {
            var item = schema[index];
            var place = order.indexOf(index);
            if (place < 0) {
                order.push(index);
                place = order.indexOf(index);
            }

            if (item.type == 'file') {
                item['data-name'] = `${item.name}`;
                delete item.name;
            }
            var cssClass;
            if (item.type == 'birthdate') {
                cssClass = 'col-md-12';
            } else {
                cssClass = 'col-md-6';
            }
            var element = `<div class="${cssClass}"><label for="${item.label}" class="${(item.required != undefined && item.required == 'on') ? 'required' : ''}" >${trans(item.label)}</label><div class="form-group theme-login-form-group">`;
            var endText = `</div></div>`;
            item.class = "form-control";
            item.id = item.label;
            item['data-placeholder'] = trans(item.label);
            delete item.label;
            if (item.type == 'countryId') {
                item.class += " theme-search-area-section-input select2 ";
                item['data-url'] = "/v1/search/countries";
                element += '<div class="theme-search-area-section-inner-select2 height-50">';
                endText += '</div>';
            }
            if (item.type == 'cityId') {
                item.class += " theme-search-area-section-input select2 ";
                item['data-url'] = "/v1/search/cities";
                element += '<div class="theme-search-area-section-inner-select2 height-50">';
                endText += '</div>';
            }
            if (item.type == 'birthdate') {
                item.class += " datePickerBirthdate _pl-40 ";
            }
            if (item.type == 'file') {
                item.class += ' user-pic';
            }
            element += former.make(item.type, item);
            element += endText;
            elements[place] = element;
        }
        buffer = { elements: elements };
        return '';
    }
    this.init();
}