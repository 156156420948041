function BackOfficeLoginProcess() {
    var me = this;
    this.reseiveMessage =

        window.addEventListener('message', function (event) {
            var userToken;
            var loginInformation = getStorage("loginInformation");
            if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
                userToken = loginInformation.token;
            }
            if (event.origin == dataActive.location.accounts) {
                event.source.postMessage(userToken, event.origin)
            }
        }, false);
}