function PassengerInfoForm(data) {
    var $form = data.form;
    var process = data.process;
    var loginInformation = getStorage("loginInformation");
    var inputsSection = $('.otp-inputs-section');
    var otpSubmit = $('.otp-submit');

    dataActive.hooks.register('afterAjaxError', () => dataActive.pageReload());
    function getFields() {
        var data = {};
        $form.find("[name]").each(function (index, element) {
            var $element = $(element);
            var elementName = $element.attr('name');
            if ($element.data('twoparts')) {
                $(`<div class="text-danger error-text" data-class="${elementName}"></div>`).insertAfter($element);
            } else {
                $(`<div class="text-danger error-text" data-class="${elementName}"></div>`).insertAfter($element.parent());
            }
            data[elementName] = $element.val();
            if ($element.attr('type') == 'birthdate') {
                var elementValue = $element.val();
                if (elementValue != '' && !elementValue.includes('NaN')) {
                    data[elementName] = DateConvert(elementValue);
                }
            }
        });
        return data;
    }

    function success(data) {
        var nights = getStorage("numberNights");
        if (data.status == true) {
            $form.find('.passenger-info').attr("disabled", true);
            $form.find('.terms-conditions-section').addClass('d-none');
            $(".night-number-pay-section").append(nights);
            if ($form.find('.img-pen').length != 0) {
                $form.find('.img-pen').hide();
            }
            if ($form.find('.remove-picker').length != 0) {
                $form.find('.remove-picker').hide();
            }
            if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
                process.checkOnholdable();
                // $(inputsSection).removeClass('d-none');
                // $(otpSubmit).on('click', mapGateWay);
            } else {
                $form.parent().next().show();
            }

            $form.find('.edit-passenger').prop("disabled", false).removeClass('d-none');
            $form.find('.search-again-button').remove();
        }
    }

    this.ajaxParams = function () {
        $form.find('.error-text').each((index, item) => {
            $(item).remove();
        });
        $form.find('.error-text-static').each((index, item) => {
            $(item).text('');
        });
        if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
            var headers = { 'Authorization': 'Bearer ' + loginInformation.token };
        }
        return {
            url: dataActive.location.marketplace + "/v1/book/guests",
            method: "post",
            headers: headers || {},
            data: getFields(),
            success: success,
            error: function (data, validation) {
                $(".book-button").prop('disabled', false);
                $('.passenger-info').attr("disabled", false);
                if (data.status == 422) {
                    $.each(data.responseJSON, function (key, value) {
                        value = (Array.isArray(value) ? value[0] : value);
                        $form.find('[data-class="' + key + '"]').text(trans(value));
                    });
                    let maxElementHeight = 0
                    $form.find('.error-text').each((index, item) => {
                        $item = $(item);
                        if ($item.height() > maxElementHeight) {
                            maxElementHeight = $item.height();
                        }
                    });
                    $form.find('.error-text').height(maxElementHeight);
                } else {
                    dataActive.defaultAjaxError(data, validation);
                }
            },
        };
    }

    // function mapGateWay() {
    //     let otpCode = '';
    //     $(inputsSection).find('input').each((index,input) => {
    //         if(input.value != "") {
    //             otpCode += input.value;
    //         }
    //     })
    //     if(otpCode.length < 6) {
    //         alert('otp kame => ', otpCode);
    //     }
    //     else {
    //         process.checkOnholdable();
    //         $(inputsSection).addClass('d-none');
    //     }
    // }
}