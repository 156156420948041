function ManualVoucherProcess(handler) {
    this.showUrl = dataActive.location.marketplace + "/v1/manualVoucher/" + dataActive.location.query.referenceId;
    this.gatewayItem;
    this.walletItems;
    var me = this;
    var invoiceAmount;
    var invoiceReferenceId;
    var loginInformation = getStorage("loginInformation");
    var gatewaySection = handler.domNode.find('.gateway-section');
    this.voucherDetail = function () {
        var loginInformation = getStorage("loginInformation");
        if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
            var headers = { 'Authorization': 'Bearer ' + loginInformation.token };
        }
        dataActive.hooks.clear('afterAjaxError');
        ajax({
            url: me.showUrl,
            method: "get",
            headers: headers || {},
            data: {},
            success: function (data) {
                invoiceAmount = data.data.amount;
                invoiceReferenceId = data.data.invoiceReferenceId;
                me.mapContent(data.data);
                me.showPaymentSection(data.data);
            },
        });
    }

    this.mapContent = function (data) {

        $(".manual-summary-voucher-page").append(view("manualInvoice/voucher-page-manual-title-section", { invoice: data }));

        $(".manual-invoice-items-table").append(view("manualInvoice/manual-voucher-items-info", { items: data.items, currency: data.currency.abb }));
        handler.domNode.find('.print').on('click', function () {
            window.print()
        });

    }

    this.showPaymentSection = function (data) {
        switch (data.invoiceStatus) {
            case 'not_paid':
                if (!$.isEmptyObject(dataActive.location.query["paymentMsg"])) {
                    swal({
                        text: trans(decodeURI(dataActive.location.query["paymentMsg"])),
                        dangerMode: true,
                        icon: "error",
                    });
                }
                if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
                    me.pay();
                    handler.domNode.find('.payment_notice').hide();
                } else {
                    handler.domNode.find('.sign-in-button').show();
                }
                break;
            case 'not_authorized':
                swal({
                    text: trans('This invoice is not payable.'),
                    dangerMode: true,
                    icon: "error",
                }).then(() => {
                    dataActive.pageRoute("index");
                });
                break;
            default:
                break;
        }
    }

    this.pay = function () {
        var invoiceInformation;
        invoiceInformation = getGateways();
        var allGateways = invoiceInformation[0][0];
        var ewalletWoMPC = invoiceInformation[0][1];
        var existGatewayWoMPC = ((!$.isEmptyObject(invoiceInformation[0][1])) && (!$.isEmptyObject(invoiceInformation[0][0]))) ? true : false;
        gatewaySection.html(view("partials/pay-section", { invoiceInformation, allGateways, ewalletWoMPC, existGatewayWoMPC }));
        handler.domNode.find('.show-extra-gateway').on('click', function () {
            if ((handler.domNode.find('.show-extra-gateway')).is(":checked")) {
                $(this).parent().next().removeClass('d-none');
            } else {
                $(this).parent().next().addClass('d-none');
            }
        });
        let maxHighestBox = 0;
        handler.domNode.find('.gateway-item').each((index, item) => {
            $item = $(item);
            if ($item.height() > maxHighestBox) {
                maxHighestBox = $item.height();
            }
        });
        handler.domNode.find('.gateway-item').height(maxHighestBox);

        let maxHightsDetailsBox = 0;
        handler.domNode.find('.gateway-detalis').each((index, item) => {
            $item = $(item);
            if ($(item).height() > maxHightsDetailsBox) {
                maxHightsDetailsBox = $item.height();
            }
        });
        handler.domNode.find('.gateway-detalis').height(maxHightsDetailsBox);
        me.gatewayItem = gatewayItem = handler.domNode.find('.gateway-items');

        gatewayItem.each((index, item) => {
            $(item).on('click', function () {
                $(item).next().click();
                handler.domNode.find('.selected-gateway').removeClass('selected-gateway');
                $(item).parent().parent().addClass('selected-gateway');
                var requirements = $(this).data('requirements');
                var works = $(this).data('works');
                var requirementSection = handler.domNode.find('.gateway-requirements');
                var worksSection = handler.domNode.find('.gateway-works');
                requirementSection.empty();
                worksSection.empty();
                requirements.forEach(item => {
                    if (item != '' && item == 'uiCallBackUrl') {
                        requirementSection.append('<input type="hidden" name="uiCallBackUrl" value="' + location.href + '">');
                    } else if (item != '') {
                        requirementSection.append(view('partials/gateway-requirements', { item: item }));
                    }
                })
                works.forEach(item => {
                    if (item.type == 'view') {
                        worksSection.append(view(fileUrl(item.src)));
                    } else if (item.type == 'script') {
                        use(fileUrl(item.src));
                    }
                })
            })
        })

        $(gatewayItem[0]).click();
        me.walletItems = handler.domNode.find('.wallet-item-input');
        $(me.walletItems[0]).click();
        var $payForm = handler.domNode.find('.pay-form');
        $(".invoice-reference-id").val(invoiceReferenceId);
        var tracker = dataActive.getObject("PayForm", { payForm: $payForm, process: me, invoiceReferenceId: invoiceReferenceId, isManual: true });
        $payForm.find('[type=submit]').attr('type', 'button').on('click', function () {
            me.payBtn = handler.domNode.find('.pay-button');
            me.payBtn.text(trans('Pay_Start'));
            me.payBtn.prop('disabled', true);
            ajax(tracker.ajaxParams());
            gatewayItem.each((index, item) => {
                $(item).prop('disabled', true);
            });
        });
    }

    function getGateways() {
        var invoiceData = [];
        if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
            var headers = { 'Authorization': 'Bearer ' + loginInformation.token };
        }
        dataActive.hooks.clear('afterAjaxError');
        ajax({
            url: dataActive.location.marketplace + "/v1/gateway/manualInvoice",
            headers: headers || {},
            method: "get",
            data: { referenceId: invoiceReferenceId },
            async: false,
            success: function (data) {
                invoiceData[0] = data.gateways;
                invoiceData[1] = data.invoiceAmount;
                invoiceData[2] = data.marketplaceCurrency;
            },
        });
        return invoiceData;
    }


    this.init = function () {
        this.voucherDetail();
    }
    this.init();
}