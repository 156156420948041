function BackOfficeLogoutProcess() {
    setTimeout(function () {
        var loginInformation = getStorage("loginInformation");
        if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
            ajax({
                url: dataActive.location.marketplace + "/v1/logout",
                method: "get",
                headers: { 'Authorization': 'Bearer ' + loginInformation.token },
                data: "",
                success: function (data) {
                    if (data.userFlag == 'registerType:socialMedia,by:Facebook') {
                        FB.logout();
                    }
                    localStorage.removeItem("loginInformation" + Constants.ver);
                    localStorage.removeItem("lastLocation" + Constants.ver);
                    dataActive.pageRoute("loginPage");
                },
            });
        } else {
            localStorage.removeItem("loginInformation" + Constants.ver);
            localStorage.removeItem("lastLocation" + Constants.ver);
            dataActive.pageRoute("loginPage");
        }
    }, 1000);
}