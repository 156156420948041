function BlogPageProcess(handler) {
    var me = this;
    var searchPostUrl = dataActive.location.marketplace + '/v1/search/posts';
    var popularPostUrl = dataActive.location.marketplace + '/v1/popular/posts';
    this.url = dataActive.location.marketplace + "/v1/posts/blog";

    this.showPost = function () {
        dataActive.hooks.register('afterAjaxError', () => dataActive.previous());
        ajax({
            url: dataActive.location.marketplace + "/v1/post/" + dataActive.location.route.params.slug,
            method: "get",
            success: function (data) {
                me.mapContent(data.data);
            },
            error: function () {
                swal({
                    text: trans('post_not_available'),
                    dangerMode: true,
                    icon: "error",
                }).then(() => {
                    dataActive.pageRoute("index");
                });
            }
        });
    }

    this.showPopular = function () {
        ajax({
            url: popularPostUrl,
            method: "get",
            success: function (data) {
               me.mapPopularContent(data.data);
            },
            error: function () {

            }
        });
    }

    this.showTravelInspirations = function() {
        ajax({
            url: me.url,
            method: "get",
            success: function(data) {
                me.mapTravelInspirations(data.data);
            }
        })
    }

    this.mapContent = function (data) {
        $("title").text(data.title);
        $("[data-meta-title]").attr('content', data.title)
        var postContent = handler.domNode.find('.post-page-content');
        postContent.append(view('posts/post-page-content-details', { data: data }));
        this.searchPost();
        if (!$.isEmptyObject(data.images.gallery)) {
            data.images.gallery.forEach((item) => {
                if(item != null) {
                    handler.domNode.find(".owl-carousel").append(view('blog/gallery', { item: item }));
                }
            });
        }
        OwlCarousel();
    }

    this.mapTravelInspirations = function(data) {
        var postsTravelSection = handler.domNode.find('.posts-travel-inspiration');
        for(let i = 0; i < 4; i++) {
            postsTravelSection.append(view("posts/popular-posts", { item: data[i]}));
        }
    }

    this.mapPopularContent = function(data) {
        var popularPostSection = handler.domNode.find(".popular-posts-sidebar");
        for(let i = 0; i < 4; i++) {
            popularPostSection.append(view("posts/popular-posts", { item: data[i]}));
        }
    }

    function OwlCarousel() {
        var navText = ['<span class="fa fa-angle-left"><span>', '<span class="fa fa-angle-right"></span>'];
        if (dataActive.dir == 'rtl') {
            navText = ['<span class="fa fa-angle-right"><span>', '<span class="fa fa-angle-left"></span>'];
        }
        handler.domNode.find('.owl-carousel').each(function () {
            var $carousel = $(this);
            $carousel.owlCarousel({
                // dots : false,
                // items : $carousel.data("items"),
                slideBy: $carousel.data("slideby"),
                center: $carousel.data("center"),
                // loop: $carousel.data("loop"),
                margin: $carousel.data("margin"),

                autoplay: $carousel.data("autoplay"),
                autoplayTimeout: $carousel.data("autoplay-timeout"),
                navText: navText,
                responsive: {
                    0: {
                        items: 1,
                        dots: true,
                        nav: false
                    },
                    500: {
                        items: 2,
                        dots: true,
                        nav: false
                    },
                    992: {
                        items: $carousel.data("items"),
                        dots: false,
                        nav: $carousel.data("nav")
                    }
                }
            });
        });
    }

    this.searchPost = function() {
        var searchInput  = handler.domNode.find(".search-post");
        var searchButton = handler.domNode.find(".search-post-button");
        searchInput.on("keyup", function(e) {
            if(e.target.value.length >= 3) {
                $(searchButton).removeAttr("disabled");
                $(searchButton).find("i").css("color", "#000");
                if(e.keyCode == 13) {
                    me.searchPostApi()
                }
            } else {
                $(searchButton).attr("disabled",true);
                $(searchButton).find("i").css("color", "#dfdfdf");
                var resultSection = handler.domNode.find(".posts-results");
                $(resultSection).empty().hide();
            }
        });
        $(searchButton).on("click", this.searchPostApi);
    }

    this.searchPostApi = function() {
        handler.domNode.find(".search-post-button").find("i").hide();
        handler.domNode.find(".search-post-button").find('.search-loader-spin').show();
        var inputVal = handler.domNode.find(".search-post-button").prev().val();
        ajax({
            url: searchPostUrl,
            method: 'get',
            data: {
                title: inputVal
            },
            success: function (data) {
                me.mapResults(data.data);
                handler.domNode.find(".search-post-button").find("i").show();
                handler.domNode.find(".search-post-button").find('.search-loader-spin').hide();
            }
        })
    }

    this.mapResults = function (data) {
        var resultSection = handler.domNode.find(".posts-results");
        $(resultSection).empty();
        resultSection.show();
        if(data.length > 0) {
            data.forEach(post => {
                resultSection.append(view("posts/posts-results", {post: post}));
            });
        } else {
            resultSection.append(`<p class='text-center my-15'> ${trans('There_Is_No_Results')} </p>`);
        }
      
    }
    

    this.init = function () {
        this.showPost();
        this.showPopular();
        this.showTravelInspirations();
    }
    this.init();
}

