function BlogProcess(handler) {
    var type = $(handler.domNode).data('type');
    this.url = dataActive.location.marketplace + "/v1/posts/" + type;
    var me = this;
    var $showMore = handler.domNode.find('.show-more');
    var $pageNumber = handler.domNode.find('.page-number');
    this.getPosts = function () {
        ajax({
            url: me.url,
            method: "get",
            success: function (data) {
                me.mapContent(data.data);
                me.loadMore(data.pages);
            },
            error: function () {

            }
        });
    }

    this.mapContent = function (data) {
        var blogSection = handler.domNode.find('.homepage-blogs-item');
        handler.domNode.removeClass('d-none');
        data.forEach((item, index) => {
            if (index < 4) {
                blogSection.append(view('blog/blog-item', { item: item }));
            }
        });
        var blogItems = handler.domNode.find('.blog-items');
        data.forEach((item, index) => {
            blogItems.append(view('blog/blog-item', { item: item }));
        });
    }

    this.showMore = function () {
        $showMore.hide();
        var pageNumber = $pageNumber.val($showMore.data('currentPage') + 1);
        var page = pageNumber.val();
        dataActive.hooks.register('afterAjaxError', this.afterAjaxError);
        ajax({
            url: me.url,
            method: "get",
            data: {
                page: page,
            },
            success: function (data) {
                me.mapContent(data.data);
                $showMore.data('currentPage', data.pages.page);
                if ($showMore.data('pageCount') > data.pages.page) {
                    $showMore.show();
                }
            },
        })
    }

    this.loadMore = function (pages) {
        if ($showMore.data('pageCount') == undefined) {
            $showMore.click(me.showMore)
        }
        $showMore.data('pageCount', pages.pageCount);
        $showMore.data('currentPage', pages.page);
        if (pages.pageCount > pages.page) {
            $showMore.show();
        } else {
            $showMore.hide();
        }
    }

    this.init = function () {
        this.getPosts();
    }
    this.init();
}