function EventSearchForm($form) {
    dataActive.hooks.clear('afterAjaxError');
    var searchButton = $form.find('.service-search-btn');
    var me = this;
    function getFields() {
        var data = {};
        $form.find("[name]").each(function (index, element) {
            var $element = $(element);
            var elementName = $element.attr('name');
            data[elementName] = $element.val();
        });
        return data;
    }

    function success(data) {
        var sessionId = data.sessionId || "";
        // lug.info("sessionId", { message: data });
        if (String(sessionId).length > 0) {
            var eventSearchDetails = getStorage("eventSearchDetails");
            eventSearchDetails["sessionId"] = sessionId;
            setStorage("eventSearchDetails", eventSearchDetails);
            dataActive.pageRoute("eventResult", data);
        } else {
            dataActive.showErrors(trans("error happend . try again later."));
        }
    }

    var loginInformation = getStorage("loginInformation");
    if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
        var headers = { 'Authorization': 'Bearer ' + loginInformation.token };
    }


    this.ajaxParams = function () {
        return {
            url: dataActive.location.marketplace + "/v1/search/event",
            headers: headers || {},
            method: "post",
            data: getFields(),
            success: success,
            error: function (data, validation) {
                me.enabelButton();
                dataActive.defaultAjaxError(data, validation);
            },
        };
    }

    this.disableButton = function () {
        searchButton.prop('disabled', true);
    }

    this.enabelButton = function () {
        searchButton.prop('disabled', false);
    }

    ///> init
    $form.find('.searcherIdentity').val(localStorage.getItem('searcherIdentity'));
    var fliterButton = $form.closest('.theme-hero-area').next().find('#mobileFilters');
    var selectCity = $form.find('.select-city');
    new CustomSelect(selectCity, { url: dataActive.location.marketplace + '/v1/search/cities' + '?lang=' + dataActive.location.query.lang.toLowerCase(), message: "Search a city" });
    $form.find('.datePickerStartEvent').on('focus', function (e) {
        e.preventDefault();
        $(this).attr("autocomplete", "off");
    });

    var monthPicker = $form.find('.month-picker');
    new MonthPicker(monthPicker, { beforeShow: () => { if ($(window).width() < 992) { fliterButton.hide() } }, afterShow: () => { if ($(window).width() < 992) { fliterButton.show() } } });
}