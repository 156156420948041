function MyBookingsProcess(handler) {
    var me = this;
    var data = {};
    this.showResult = function () {
        ajax({
            url: dataActive.location.marketplace + "/v1/mybooking",
            method: "get",
            headers: { 'Authorization': 'Bearer ' + getStorage("loginInformation").token },
            data: data,
            success: function (data) {
                me.mapContent(data.data)
            },
        });
    }
    this.mapContent = function (data) {
        const bookingStatus = { 'contact admin': 'warning', 'contact_admin': 'warning', 'waiting issue': 'success', 'waiting_issue': 'success', 'failed': 'danger', 'canceled': 'danger', 'issued': 'success', 'initialized': 'warning', 'validated': 'warning', 'book': 'warning', 'expired': 'danger', 'on_hold': 'success', 'on hold': 'success', 'verify': 'warning', '-': '', 'incomplete': 'success' };
        const invoiceStatus = { 'confirmed': 'success', 'refunded': 'warning', 'not authorized': 'warning', 'not_authorized': 'warning', 'not paid': 'danger', 'paid': 'success', '-': '', 'not_paid': 'danger' }
        data.forEach((item, index) => {
            let passengers;
            if (item.travelService == 'visa') {
                passengers = `0 ${trans("Travelers")}`
            } else if (item.travelService == 'package') {
                passengers = `0 ${trans("ADT")}, 0 ${trans("CHD")}, 0 ${trans("CHDWOBed")}, 0 ${trans("INF")}`
            } else {
                passengers = `0 ${trans("ADT")}, 0 ${trans("CHD")}, 0 ${trans("INF")}`
            }

            if (item.passengerInfo) {
                switch (item.travelService) {
                    case "visa":
                        passengers = `${item.passengerInfo.length} ${trans("Travelers")}`
                        break;
                    case "tour":
                    case "event":
                    case "flight":
                        const passengersList = item.passengerInfo
                        const totalAdults = passengersList.adults.length
                        const totalChildren = passengersList.children ? passengersList.children.length : 0
                        const totalInfants = passengersList.infants ? passengersList.infants.length : 0
                        passengers = `${totalAdults} ${trans("ADT")}, ${totalChildren} ${trans("CHD")}, ${totalInfants} ${trans("INF")}`
                        break;
                    case "hotel":
                        item.passengerInfo.forEach(passenger => {
                            adults = passenger.adult.length
                            children = passenger.children ? passenger.children.length : 0
                            infants = passenger.infant ? passenger.children.infants : 0
                        })
                        passengers = `${adults} ${trans("ADT")}, ${children} ${trans("CHD")}, ${infants} ${trans("INF")}`
                        break;
                    case "package":
                        let adultsSum = 0;
                        let childrenSum = 0;
                        let childrenWOSum = 0;
                        let infantsSum = 0;
                        for (let rooms of Object.values(item.passengerInfo)) {
                            rooms.forEach(room => {
                                adultsSum += (room.adults || {}).length ?? ''
                                childrenSum += room.children ? room.children.length : 0
                                childrenWOSum += room.childrenWOBed ? room.childrenWOBed.length : 0
                                infantsSum += room.infants ? room.infants.length : 0
                            })
                        }
                        passengers = `${adultsSum} ${trans("ADT")}, ${childrenSum} ${trans("CHD")}, ${childrenWOSum} ${trans("CHDWOBed")}, ${infantsSum} ${trans("INF")}`
                        break;
                }
            }

            item.paymentStatusColor = invoiceStatus[item.paymentStatus]
            item.statusColor = bookingStatus[item.status]
            handler.domNode.find('.bookings-detail').append(view("partials/bookings-detail-row-in-my-bookings", { indexOfItem: index, item, passengers }))
        });

        handler.domNode.find('.please-wait').hide()

        handler.domNode.find('#bookingsTable').DataTable({
            ordering: false,
            language: {
                search: trans("Search"),
                lengthMenu: `${trans("Display")} _MENU_ ${trans("records per page")}`,
                info: `${trans("Showing page")} _PAGE_ ${trans("of")} _PAGES_`,
                infoEmpty: trans('No records available'),
                infoFiltered: `(${trans("filtered from")} _MAX_ ${trans("total records")})`,
                paginate: {
                    "first": trans("First"),
                    "previous": trans("Previous"),
                    "next": trans("Next"),
                    "last": trans("Last")
                },
            },
            "columnDefs": [
                { "className": "dt-center", "targets": "_all" }
            ],
            responsive: {
                details: {
                    renderer: function (api, rowIdx, columns) {
                        var data = $.map(columns, function (col, i) {
                            return col.hidden ?
                                '<tr data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                                '<td>' + col.title + ':' + '</td> ' +
                                '<td>' + col.data + '</td>' +
                                '</tr>' :
                                '';
                        }).join('');

                        return data ?
                            $('<table/>').append(data) :
                            false;
                    },
                }
            }
        });
    }

    this.init = function () {
        me.showResult();
    }

    this.loadDataTableCss = () => {
        const dataTableStyles = document.createElement('link');
        dataTableStyles.href = "https://cdn.infra.luxota.cloud/assets/css/datatables.min.css";
        dataTableStyles.rel = 'stylesheet'
        document.head.appendChild(dataTableStyles)
    }

    this.loadDataTableCss()
    dataActive.use("https://cdn.infra.luxota.cloud/assets/js/datatable.js", this.init)
}
