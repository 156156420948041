function TrackOrderForm($form) {
    dataActive.hooks.clear('afterAjaxError');
    var searchButton = $form.find('button');
    var me = this;
    function success(data) {
        if (data.bookingStatus == 'issued' || data.bookingStatus == 'on_hold' || data.bookingStatus == 'waiting_issue' || data.bookingStatus == 'incomplete') {
            dataActive.pageRoute((data.travelService + "Voucher"), { referenceId: data.referenceId });
        } else {
            dataActive.showErrors(trans('Reservation_Status') + ' ' + data.bookingStatus + ' ' + trans('voucher not available'));
            me.enabelButton();
        }
    }

    var loginInformation = getStorage("loginInformation");
    if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
        var headers = { 'Authorization': 'Bearer ' + loginInformation.token };
    }

    this.ajaxParams = function () {
        return {
            url: dataActive.location.marketplace + "/v1/trackorder",
            method: "post",
            headers: headers || {},
            data: $form.serialize(),
            success: success,
            error: function (data, validation) {
                me.enabelButton();
                dataActive.defaultAjaxError(data, validation);
            },
        };
    }

    this.disableButton = function () {
        searchButton.prop('disabled', true);
    }

    this.enabelButton = function () {
        searchButton.prop('disabled', false);
    }
}