function DateConvert(date) {
    if (DateConvert.calendar == undefined) {
        throw new Error('calendar Not config for date format');
    }

    var calendar = DateConvert.calendar;

    var convertJalalianToGregorian = function (date) {
        var date = (date).split('-');
        return dayjs((new persianDate([parseInt(date[0]), parseInt(date[1]), parseInt(date[2])])).toDate()).format("YYYY-MM-DD");
    }

    if (calendar == 'jalali') {
        return convertJalalianToGregorian(date);
    } else if (calendar == 'gregorian') {
        return date;
    }
}