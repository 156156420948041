function ComingSoonProcess(handler) {

    var src = getConfig('logo');
    $('#theme-abs-logo').html("<img src='"+ src +"' alt='Image Alternative text'  title='Image Title' />");

    this.comingSoonCountdown =  function () {
        $('#commingSoonCountdown').countdown('2020/12/10', function (e) {
            $(this).html(e.strftime('' +
                '<div><p id="days">%D</p><span>days</span></div>' +
                '<div><p>%H</p><span>hours</span></div>' +
                '<div><p>%M</p><span>minutes</span></div>' +
                '<div><p>%S</p><span>seconds</span></div>'
            ));
        });
    }
    this.comingSoonCountdownMobile =  function () {
        $('#commingSoonCountdownMobile').countdown('2020/12/10', function (e) {
            $(this).html(e.strftime('' +
                "<h2 class='theme-hero-text-title'>Launch in<br />" +
                "<b>%D Days</b>" +
                "</h2>"
            ));
        });
    }
    this.comingSoonCountdown();
    this.comingSoonCountdownMobile();
}