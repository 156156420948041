function packageSearchForm($form) {
    const packageTab = $form.closest('[data-process="Banner"]').find('#package-tab')
    dataActive.hooks.clear('afterAjaxError');
    var searchButton = $form.find('.service-search-btn');
    var me = this;
    var lang = dataActive.location.query.lang.toLowerCase();
    function getFields() {
        var data = {};
        $form.find("[name]").each(function (index, element) {
            var $element = $(element);
            var elementName = $element.attr('name');
            data[elementName] = $element.val();
        });
        return data;
    }

    function success(data) {
        var sessionId = data.sessionId || "";
        // lug.info("sessionId", { message: data });
        if (String(sessionId).length > 0) {
            var packageSearchDetails = getStorage("packageSearchDetails");
            packageSearchDetails["sessionId"] = sessionId;
            setStorage("packageSearchDetails", packageSearchDetails);
            dataActive.pageRoute("packageResult", data);
        } else {
            dataActive.showErrors(trans("error happend . try again later."));
        }
    }

    var loginInformation = getStorage("loginInformation");
    if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
        var headers = { 'Authorization': 'Bearer ' + loginInformation.token };
    }


    this.ajaxParams = function () {
        return {
            url: dataActive.location.marketplace + "/v1/search/package",
            headers: headers || {},
            method: "post",
            data: getFields(),
            success: success,
            error: function (data, validation) {
                me.enabelButton();
                dataActive.defaultAjaxError(data, validation);
            },
        };
    }

    this.disableButton = function () {
        searchButton.prop('disabled', true);
    }

    this.enabelButton = function () {
        searchButton.prop('disabled', false);
    }

    function getPackageCountries() {
        var citiesList = $form.find('.cities-list');
        var departuresList = $form.find(".departures-list");
        citiesList.empty();
        departuresList.empty();
        citiesList.append(`<option value="">${trans('Where')}</option>`);
        departuresList.append(`<option value="">${trans('Departure From')}</option>`);
        ajax({
            url: dataActive.location.marketplace + '/v1/search/destinations/package',
            method: 'get',
            headers: headers || {},
            data: {},
            success: function (data) {
                data.data.cities.forEach(element => {
                    citiesList.append(`<option value="${element.id}" ${citiesList.data('value') == element.id ? 'selected' : ''}> ${(element[lang] || element['en']) + ', ' + (element['country'][lang] || element['country']['en'])}</option>`);
                });
                data.data.departures.forEach(element => {
                    departuresList.append(`<option value="${element.id}" ${departuresList.data('value') == element.id ? 'selected' : ''}> ${element[lang] || element['en']}, ${element['country'][lang] || element['country']['en']}</option>`)
                })
            }
        })
    }
    ///> init    
    $form.find('.searcherIdentity').val(localStorage.getItem('searcherIdentity'));
    if (window.location.pathname === "/" || window.location.pathname === "/home") {
        packageTab.on('click', () => {
            getPackageCountries();
        })
    } else {
        getPackageCountries();
    }
    var fliterButton = $form.closest('.theme-hero-area').next().find('#mobileFilters');
    $form.find('.datePickerStartPackage').on('focus', function (e) {
        e.preventDefault();
        $(this).attr("autocomplete", "off");
    });

    var monthPicker = $form.find('.month-picker');
    new MonthPicker(monthPicker, { beforeShow: () => { if ($(window).width() < 992) { fliterButton.hide() } }, afterShow: () => { if ($(window).width() < 992) { fliterButton.show() } } });
}