function TourPageProcess(handler) {
    this.showUrl = dataActive.location.marketplace + "/v1/profile/tour";
    this.currentCurrency;
    var me = this;
    var loginInformation = getStorage("loginInformation");

    this.showResult = function () {
        dataActive.hooks.register('afterAjaxError', () => dataActive.pageRoute('tourResult', { status: true, sessionId: getStorage("tourSearchDetails").sessionId }));
        ajax({
            url: me.showUrl,
            method: "get",
            data: {
                referenceId: dataActive.location.query.referenceId
            },
            headers: { 'Authorization': 'Bearer ' + loginInformation.token },
            success: function (data) {
                // lug.info("tour page found");
                me.mapContent(data.data);
                me.currentCurrency = data.data['priceInfo']['currency'].abb;
                setStorage("tourDetail", data);
            },
        });
    }
    this.mapContent = function (data) {
        $("title").append(":" + data.serviceInfo.title);
        $("[data-meta-title]").attr('content', data.serviceInfo.title)
        handler.domNode.find(".loader-area").hide();
        handler.domNode.find(".tour-page-profile").show();
        var tourContent = handler.domNode.find(".tour-page-profile");
        var travelStyle = data.serviceInfo.travelStyle;
        var capacity;
        var travelStyleItem = "";
        for (var i in travelStyle) {
            travelStyleItem += `<li>${trans(travelStyle[i])}</li>`;
        }
        tourContent.append(view("tour/tour-profile", { data: data, travelStyleItem: travelStyleItem }));
        $('.fotorama').fotorama();
        var itineraryItem = handler.domNode.find(".itinerary-items");
        var image;
        var itineraryLength = data.serviceInfo.itinerary.length;
        data.serviceInfo.itinerary.forEach((item, index) => {
            if (item.photo == null) {
                image = "";
            } else {
                image = '<img src="' + fileUrl(item.photo, 'profile-image') + '" alt="' + item.title + '" title="' + item.title + '" class="pull-left width-30  _mr-10 itinerary-img">';
            }
            itineraryItem.append(view("tour/tour-itinerary", { item: item, indexItem: index, image: image, itineraryLength: itineraryLength }));
        });
        var availibilityItem = handler.domNode.find(".availabilities");
        var $form = handler.domNode.find(".passanger-form");
        $form.find(".reference-id").val(dataActive.location.query.referenceId)
        data.serviceInfo.availabilities.forEach((item) => {
            if (item.remaining_capacity > 10) {
                capacity = "10+" + trans('seats_left');
            } else {
                capacity = item.remaining_capacity + trans('seats_left');
            }
            var $view = $(view("partials/availibilities", { data: data, item: item, capacity: capacity }));
            var instanseButton = $view.find(".instant-book");
            if (item.remaining_capacity == 0) {
                instanseButton.prop('disabled', true);
                instanseButton.text(trans('Full'));
            }
            instanseButton.data("availibilityId", item.id);
            instanseButton.data("adultPrice", data.priceInfo.availabilities[item.id].payable.adult);
            instanseButton.data("childrenPrice", data.priceInfo.availabilities[item.id].payable.child);
            instanseButton.data("infantPrice", data.priceInfo.availabilities[item.id].payable.infant);
            instanseButton.click(function () {
                $form.find(".availibility-id").val($(this).data("availibilityId"));
                $form.find(".adult-price").val($(this).data("adultPrice"));
                $form.find(".children-price").val($(this).data("childrenPrice"));
                $form.find(".infant-price").val($(this).data("infantPrice"));
                me.calculate();
                $('.passanger-information').magnificPopup('open');
            });
            availibilityItem.append($view);
        });
        $form.find(".quantity-selector-template-decrement").click(me.calculate);
        $form.find(".quantity-selector-template-increment").click(me.calculate);

        var landmarks = handler.domNode.find(".owl-carousel");
        data.serviceInfo.places.forEach((item) => {
            if ($.isEmptyObject(item.landmarks)) {
                return;
            } else {
                landmarks.append(view("tour/landmarks", { item: item }));
            }
        });
        $('.passanger-information').magnificPopup({
            type: 'inline',
            fixedContentPos: true
        });
        OwlCarousel();
        handler.domNode.find('.quantity-selector-template').quantitySelectorTemplate();

        var pageTitle = handler.domNode.find(".page-title");
        var pageTitleText = handler.domNode.find(".profile-page-title").text();
        var newTitle = eval("`" + pageTitleText + "`");
        pageTitle.append(newTitle);

        handler.domNode.find(".passanger-form").submit(me.booking);
        if ($(window).width() > 992) {
            $('.sticky-col').stick_in_parent({
                parent: $('#sticky-parent')
            });
        }
    }

    this.calculate = function () {
        $form = $(".passanger-form");
        var adultNumber = $form.find(".adult").val();
        var childrenNumber = $form.find(".children").val();
        var infantNumber = $form.find(".infant").val();
        var totalPrice = adultNumber * $form.find(".adult-price").val() + childrenNumber * $form.find(".children-price").val() + infantNumber * $form.find(".infant-price").val();
        totalPrice = currencyFormatter(totalPrice);
        $form.find(".total-price").text(totalPrice);
        $form.find(".currency-abbreviation").text(me.currentCurrency);
    }

    this.booking = function (e) {

        $('.tour-book-now').attr('disabled', true);
        $('.tour-book-now').html('<i class="fa fa-circle-o-notch fa-spin buttonload "></i>');
        dataActive.hooks.register('afterAjaxError', () => dataActive.pageReload());
        e.preventDefault();
        var form = $(this);
        var loginInformation = getStorage("loginInformation");
        if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
            var headers = { 'Authorization': 'Bearer ' + loginInformation.token };
        }
        ajax({
            url: dataActive.location.marketplace + "/v1/book/tour/create",
            method: "post",
            headers: headers || {},
            data: form.serialize(),
            success: function (data) {
                $('.passanger-information').magnificPopup('close');
                dataActive.pageRoute("passengerInfo", { referenceId: data.bookingReferenceId, type: "tour" });
            },
            error: function (data, validation) {
                dataActive.defaultAjaxError(data, validation);
                $('.package-book-now').attr('disabled', false);
                $('.package-book-now').html(trans("Book_Now"));
                $('.passanger-information').magnificPopup('close');
            }
        });
    }

    function OwlCarousel() {
        var navText = ['<span class="fa fa-angle-left"><span>', '<span class="fa fa-angle-right"></span>'];
        if (dataActive.dir == 'rtl') {
            navText = ['<span class="fa fa-angle-right"><span>', '<span class="fa fa-angle-left"></span>'];
        }
        $('.owl-carousel').each(function () {
            var $carousel = $(this);
            $carousel.owlCarousel({
                // dots : false,
                // items : $carousel.data("items"),
                slideBy: $carousel.data("slideby"),
                center: $carousel.data("center"),
                // loop: $carousel.data("loop"),
                margin: $carousel.data("margin"),

                autoplay: $carousel.data("autoplay"),
                autoplayTimeout: $carousel.data("autoplay-timeout"),
                navText: navText,
                responsive: {
                    0: {
                        items: 1,
                        dots: true,
                        nav: false
                    },
                    992: {
                        items: $carousel.data("items"),
                        dots: false,
                        nav: $carousel.data("nav")
                    }
                }
            });
        });
    }
    this.init = function () {
        this.showResult();
        dataActive.hooks.register('changeCurrency', function (carrency) {
            if (currencyChangeAlert()) {
                return carrency;
            }
        });
        dataActive.hooks.register('changeLanguage', function (language) {
            if (languageChangeAlert()) {
                return language;
            }
        });
    }
    this.init();
}
