function PasswordChangeForm($form) {
    dataActive.hooks.clear('afterAjaxError');
    var me = this;
    var changePasswordButton = $form.find('.change-password-btn');
    function getFields() {
        var data = {};
        $form.find("[name]").each(function (index, element) {
            var $element = $(element);
            var elementName = $element.attr('name');
            data[elementName] = $element.val();
        });
        data.token = dataActive.location.query.token;
        data.email = dataActive.location.query.email;
        return data;
    }

    function success(data) {
        if (data.status == false) {
            swal({
                text: data.message,
                icon: "error",
                dangerMode: true,
            })
                .then(() => {
                    dataActive.pageRoute("loginPage");
                });
        } else {
            if (!$.isEmptyObject(data)) {
                setStorage("loginInformation", data.data);
            }
            swal({
                text: trans('Your password was changed successfully'),
                icon: "success",
                dangerMode: true,
            })
                .then(() => {
                    dataActive.pageRoute("index");
                });
        }
    }
    this.ajaxParams = function () {
        return {
            url: dataActive.location.marketplace + "/v1/reset/password",
            method: "post",
            data: getFields(),
            success: success,
            error: function (data, validation) {
                me.enabelButton();
                dataActive.defaultAjaxError(data, validation);
            },
        };
    }

    this.disableButton = function () {
        changePasswordButton.prop('disabled', true);
    }

    this.enabelButton = function () {
        changePasswordButton.prop('disabled', false);
    }


    $form.find(".reveal").on('click', function () {
        var $pwd = $(this).parent().prev();
        var eyeIcon = $(this).children();
        if ($pwd.attr('type') === 'password') {
            eyeIcon.removeClass('fa-eye-slash');
            eyeIcon.addClass('fa-eye');
            $pwd.attr('type', 'text');
        } else {
            $pwd.attr('type', 'password');
            eyeIcon.removeClass('fa-eye');
            eyeIcon.addClass('fa-eye-slash');
        }
    });
}