function TourVoucherProcess(handler) {
    this.showUrl = dataActive.location.marketplace + "/v1/book/" + dataActive.location.query.referenceId + "/voucher";
    var me = this;
    var leadPassenger = '';
    var loginInformation = getStorage("loginInformation");

    this.voucherDetail = function () {
        dataActive.hooks.clear('afterAjaxError');
        ajax({
            url: me.showUrl,
            method: "get",
            data: {},
            headers: { 'Authorization': 'Bearer ' + loginInformation.token },
            success: function (data) {
                me.mapContent(data.data);
            },
        });
    }

    this.mapContent = function (data) {
        handler.domNode.find('.site-config').append(view("partials/site-config", { bookedBy: data.bookedBy }));
        handler.domNode.find(".tour-summary-voucher-page").append(view("tour/voucher-page-tour-title-section", { data: data }));
        var passengers = data.passengerInfo;
        var passengerForm = data.passsengersForm;
        for (const passengerType in passengers) {
            passengers[passengerType].forEach((passenger, index) => {
                if (passengerType == 'adults' && index == 0) {
                    leadPassenger = passenger[passengerForm['all']['first_name'][0]['caption']] + '-' + passenger[passengerForm['all']['last_name'][0]['caption']];
                }
                handler.domNode.find('.passenger-table-body').append(view('tour/passenger-info-in-voucher', { passenger: passenger, passengerForm: passengerForm, data: data }));
            });
        }
        if (data.serviceInfo.tripLenght.days != 0) {
            var tripLenght = Math.ceil(data.serviceInfo.tripLenght.days) + trans('days');
        } else if (data.serviceInfo.tripLenght.days == 0 && data.serviceInfo.tripLenght.hours != 0) {
            var tripLenght = data.serviceInfo.tripLenght.hours + trans('hours');
        } else if (tripLenght = data.serviceInfo.tripLenght.days == 0 && data.serviceInfo.tripLenght.hours == 0) {
            var tripLenght = data.serviceInfo.tripLenght.minutes + trans('minutes');
        }
        handler.domNode.find(".availibiltes-table").append(view("tour/tour-voucher-tour-info", { data: data, tripLenght: tripLenght }));
        handler.domNode.find(".service-inclusions").append(`<ul class="theme-package-page-details-list">${data.serviceInfo.inclusions.map(function (item) { return '<li>' + item + '</li>' }).join("")}</ul>`);
        handler.domNode.find(".service-exclusions").append(`<ul class="theme-package-page-details-list">${data.serviceInfo.exclusions.map(function (item) { return '<li>' + item + '</li>' }).join("")}</ul>`);
        handler.domNode.find(".service-important-information").append(`<ul class="theme-package-page-details-list">${data.serviceInfo.importantInformations.map(function (item) { return '<li>' + item + '</li>' }).join("")}</ul>`);
        handler.domNode.find(".service-cancelation-policies").append(`<ul class="theme-package-page-details-list">${data.serviceInfo.cancelationPolicies.map(function (item) { return '<li>' + item + '</li>' }).join("")}</ul>`);
        handler.domNode.find('.print').on('click', function () {
            document.title = 'Tour-Voucher-' + leadPassenger.replace(/ /g, "-");
            window.print()
        });

        if (data.invoiceStatus == 'not_paid') {
            handler.domNode.find('.pay-button').append("<button class='btn btn-success issue' type='button'>" + trans('Pay') + "</button>");
        }

        handler.domNode.find('.issue').click(function () {
            dataActive.pageRoute("passengerInfo", { referenceId: data.bookingReferenceId, type: "tour" });
        });
    }
    this.init = function () {
        this.voucherDetail();
    }
    this.init();
}