function TourResultProcess(handler) {
    this.progressUrl = dataActive.location.marketplace + "/v1/search/progress";
    this.resultUrl = dataActive.location.marketplace + "/v1/search/results";
    this.currentCurrency;
    var intervalId;
    var progressFinished;
    var isProgressFinished = false;
    var delay = 3000;
    var me = this;
    var waitTime = 30000;
    var $resultItem = handler.domNode.find(".tour-item");
    var $moreResult = handler.domNode.find(".more-result");
    var $waiting = handler.domNode.find(".search-loader");
    var $filters = handler.domNode.find(".tour-filters");
    var $filtersForm;
    var $pageNumber;
    var messageCode = "t<wt";
    var searchBtn = handler.domNode.prev().find('.service-search-btn');
    var headers = {};
    var loginInformation = getStorage("loginInformation");
    if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
       headers = { 'Authorization': 'Bearer ' + loginInformation.token };
    }

    searchBtn.prop('disabled', true);
    dataActive.hooks.register('afterAjaxError', this.afterAjaxError);

    this.checkProgress = function () {
        ajax({
            url: me.progressUrl,
            method: "get",
            data: {
                sessionId: dataActive.location.query.sessionId
            },
            headers: headers || {},
            success: function (data) {
                if (data.percent == 100) {
                    me.searchResult(messageCode + ",p=100");
                    isProgressFinished = true;
                }
            },
        });
    }

    this.progressFinished = function () {
        messageCode = "t>wt";
        if (isProgressFinished == false) {
            ajax({
                url: me.progressUrl,
                method: "get",
                data: {
                    sessionId: dataActive.location.query.sessionId
                },
                headers: headers || {},
                success: function (data) {
                    if (data.percent == 0) {
                        me.manageResults(messageCode + ",p=0")
                    } else if (data.percent < 100) {
                        me.searchResult(messageCode + ",p<100");
                    } else {
                        me.searchResult(messageCode + ",p=100");
                    }
                },
            });
        }
    }

    this.searchResult = function (latestMessageCode) {
        ajax({
            url: me.resultUrl,
            method: "get",
            data: {
                sessionId: dataActive.location.query.sessionId
            },
            headers: headers || {},
            success: function (data) {
                searchBtn.prop('disabled', false);
                me.currentCurrency = data.queryParams['marketplace_currency'].abb;
                if (data.data.length == 0) {
                    me.manageResults(latestMessageCode + ",d=0");
                    return;
                }
                me.manageResults(latestMessageCode + ",d!=0", data);
                messageCode = "t>wt,loaded";
            },
        })
    }

    this.moreResult = function () {
        $moreResult.hide();
        $waiting.show();
        $pageNumber.val(parseInt($moreResult.data('currentPage')) + 1);
        ajax({
            url: me.resultUrl,
            method: "get",
            data: $filtersForm.serialize(),
            headers: headers || {},
            success: function (data) {
                me.mapContent(data.data);
                $moreResult.data('currentPage', data.pages.page);
                if ($moreResult.data('pageCount') > parseInt(data.pages.page)) {
                    $moreResult.show();
                }
            },
        })
    }

    this.mapFilters = function (filters) {
        $filters.empty();
        $filters.append(view("tour/tour-filters", { filters: filters }));
        checkboxes();

        $filtersForm = $filters.find('.tour-filter-form');
        $pageNumber = $filtersForm.find(".page-number");
    }

    this.configFilters = function (filters, pages) {
        $filters.find(".apply-filter").click(function () {
            me.applyFilters();
            $filters.next().click();
        });
        $filters.find(".reset-filter").click(function () {
            $filtersForm[0].reset();
            $filters.empty();
            me.mapFilters(filters);
            me.configFilters(filters, pages);
            me.applyFilters();
            $filters.next().click();
        });
        $filters.find(".session-id").val(dataActive.location.query.sessionId);
        $pageNumber.val(1);
        this.loadMore(pages);
    }

    this.loadMore = function (pages) {
        if ($moreResult.data('pageCount') == undefined) {
            $moreResult.click(me.moreResult)
        }
        $moreResult.data('pageCount', pages.pageCount);
        $moreResult.data('currentPage', pages.page);
        if (pages.pageCount > parseInt(pages.page)) {
            $moreResult.show();
        } else {
            $moreResult.hide();
        }
    }

    this.applyFilters = function () {
        $resultItem.empty();
        $moreResult.hide();
        $waiting.show();
        $pageNumber.val(1);
        ajax({
            url: me.resultUrl,
            method: "get",
            data: $filtersForm.serialize(),
            headers: headers || {},
            success: function (data) {
                me.mapContent(data.data);
                $pageNumber.val(2);
                me.loadMore(data.pages);
            },
        })
    }

    this.mapContent = function (data) {
        $waiting.hide();
        data.forEach((item, indexItem) => {
            var travelStyle = item.serviceInfo.travelStyle;
            var travelStyleItem = "";
            var availibilteyItem = "";
            for (var i in travelStyle) {
                travelStyleItem += `<li class="_ml-5"><i class="fa fa-check"></i> ${trans(travelStyle[i])} </li>`;
            }
            item.serviceInfo.availabilities.forEach((item, index) => {
                var now = dayjs(new Date()).format('YYYY-MM-DD'); //todays date
                var end = dayjs(item.from); // another date
                var days = end.diff(now, 'day');
                var month = end.diff(now, 'month');
                if (index < 3) {
                    availibilteyItem += `<div class=row><span class="col-md-7">${trans('next')}<span class="custom-font">${((days < 30) ? days + "  " + trans('days') : month + "  " + trans('month'))}</span></span><span class="col-md-5">${DateFormat(item.from, 'MD')}</span></div>`;
                }
            });
            var $tourItem = $(view("tour/tour-item", { item: item, travelStyleItem: travelStyleItem, availibilteyItem: availibilteyItem, indexItem: indexItem }));
            $resultItem.append($tourItem);
            me.viewAvailabilites($tourItem);
        });
    }

    this.viewAvailabilites = function (tourItem) {
        var availabilitesSection = tourItem.find('.availabilites-section');
        var waitingMessage = tourItem.find('.pls-wati');
        tourItem.find('.tour-availabilites').click(function () {
            var arrow = tourItem.find('.angle-arrow');
            arrow.empty();
            var collapseSection = tourItem.find('.collapse');
            if (collapseSection.hasClass('in')) {
                arrow.append('<i class="fa fa-chevron-down" aria-hidden="true"></i>');
            } else {
                arrow.append('<i class="fa fa-chevron-up" aria-hidden="true"></i>');
            }
            if (availabilitesSection.children().length == 0) {
                ajax({
                    url: dataActive.location.marketplace + "/v1/profile/tour",
                    method: "get",
                    data: {
                        referenceId: $(this).data('referenceid'),
                    },
                    success: function (data) {
                        // lug.info("Tour page found");
                        me.mapAvailabilites(data.data, availabilitesSection);
                        waitingMessage.hide();

                    },
                });
            }
        });
    }

    this.mapAvailabilites = function (data, availabilitesSection) {
        var popupModal = handler.domNode.find('.modal-get-passenger-count');
        var form = popupModal.find('form');
        form.find(".reference-id").val(data.tourReferenceId);
        data.serviceInfo.availabilities.forEach((item) => {
            if (item.remaining_capacity > 10) {
                capacity = "10+" + trans('seats_left');
            } else {
                capacity = item.remaining_capacity + trans('seats_left');
            }
            var $view = $(view("partials/availibilities", { data: data, item: item, capacity: capacity }));
            var instanseButton = $view.find(".instant-book");
            if (item.remaining_capacity == 0) {
                instanseButton.prop('disabled', true);
                instanseButton.text(trans('Full'));
            }
            instanseButton.data("availibilityId", item.id);
            instanseButton.data("adultPrice", data.priceInfo.availabilities[item.id].payable.adult);
            instanseButton.data("childrenPrice", data.priceInfo.availabilities[item.id].payable.child);
            instanseButton.data("infantPrice", data.priceInfo.availabilities[item.id].payable.infant);
            instanseButton.click(function () {
                form.find(".availibility-id").val($(this).data("availibilityId"));
                form.find(".adult-price").val($(this).data("adultPrice"));
                form.find(".children-price").val($(this).data("childrenPrice"));
                form.find(".infant-price").val($(this).data("infantPrice"));
                me.calculate();
                popupModal.find('.open-modal').magnificPopup('open');
            });
            availabilitesSection.append($view);
        });
        form.find(".quantity-selector-template-decrement").click(me.calculate);
        form.find(".quantity-selector-template-increment").click(me.calculate);
        popupModal.find('.open-modal').magnificPopup({
            type: 'inline',
            fixedContentPos: true
        });
        form.find('.quantity-selector-template').quantitySelectorTemplate();
        form.find('.service-book-now').click(me.booking);
    }

    this.calculate = function () {
        var $form = $('.get-passenger-count-form');
        var adultNumber = $form.find(".adult").val();
        var childrenNumber = $form.find(".children").val();
        var infantNumber = $form.find(".infant").val();
        var totalPrice = adultNumber * $form.find(".adult-price").val() + childrenNumber * $form.find(".children-price").val() + infantNumber * $form.find(".infant-price").val();
        totalPrice = currencyFormatter(totalPrice);
        $form.find(".total-price").text(totalPrice);
        $form.find(".currency-abbreviation").text(me.currentCurrency);
    }

    this.booking = function () {
        var $this = $(this);
        $this.attr('disabled', true);
        $this.html('<i class="fa fa-circle-o-notch fa-spin buttonload "></i>');
        dataActive.hooks.register('afterAjaxError', () => dataActive.pageReload());
        var form = $this.parent();
        var loginInformation = getStorage("loginInformation");
        if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
            var headers = { 'Authorization': 'Bearer ' + loginInformation.token };
        }
        ajax({
            url: dataActive.location.marketplace + "/v1/book/tour/create",
            method: "post",
            headers: headers || {},
            data: form.serialize(),
            success: function (data) {
                $('.modal-get-passenger-count').magnificPopup('close');
                dataActive.pageRoute("passengerInfo", { referenceId: data.bookingReferenceId, type: "tour" });
            },
            error: function (data, validation) {
                dataActive.defaultAjaxError(data, validation);
                $this.attr('disabled', false);
                $this.html(trans("Book_Now"));
                $('.modal-get-passenger-count').magnificPopup('close');
            }
        });
    }

    this.manageResults = function (latestMessageCode, data) {
        switch (latestMessageCode) {
            case "t<wt,p=100,d=0":
                me.showNotFoundError();
                me.kill();
                break;
            case "t<wt,p=100,d!=0":
                me.mapData(data);
                me.kill();
                break;
            case "t>wt,p=0":
            case "t>wt,p<100,d=0":
                me.showAlertQuestion("No Response!", "Wait More", "Try.Again!");
                break;
            case "t>wt,p<100,d!=0":
                me.mapData(data);
                break;
            case "t>wt,p=100,d=0":
                me.showNotFoundError();
                me.kill();
                break;
            case "t>wt,p=100,d!=0":
                me.mapData(data);
                me.kill();
                break;
            case "t>wt,loaded,p=100,d!=0":
                me.alertReloade("More result found. Do  you wish to reload now?", "N0", "Yes");
                break;
            default:
                me.showNotFoundError();
                me.kill();
                break;
        }


    }

    this.showNotFoundError = function () {
        $waiting.hide();
        handler.domNode.find('.show-not-found-message').show();
    }

    this.showAlertQuestion = function (text, cancelText, confirmText) {
        swal({
            text: trans(text),
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: trans(cancelText),
                    value: null,
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                confirm: {
                    text: trans(confirmText),
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true
                }
            }
        })
            .then((response) => {
                if (response == true) {
                    searchBtn.click();
                }
            });
    }

    this.alertReloade = function (text, cancelText, confirmText) {
        swal({
            text: trans(text),
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: trans(cancelText),
                    value: null,
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                confirm: {
                    text: trans(confirmText),
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true
                }
            }
        })
            .then((response) => {
                if (response == true) {
                    me.kill();
                    ajax({
                        url: dataActive.location.marketplace + "/v1/search/refresh",
                        method: "get",
                        data: {
                            sessionId: dataActive.location.query.sessionId
                        },
                        success: function (filters) {
                            $resultItem.empty();
                            me.mapContent(data.data, data.pages);
                            me.mapFilters(filters);
                            me.configFilters(filters, data.pages);

                        }
                    })
                }
            });
    }

    this.mapData = function (data) {
        me.mapContent(data.data, data.pages);
        me.mapFilters(data.filters);
        me.configFilters(data.filters, data.pages);
    }

    this.afterAjaxError = function () {
        dataActive.pageRoute('index');
    };

    this.kill = function () {
        clearInterval(intervalId);
        clearInterval(progressFinished);
    }

    if ($(window).width() > 992) {
        dataActive.hooks.register('afterPageRoute', function () {
            $('.sticky-col').stick_in_parent({
                parent: $('#sticky-parent')
            });
        });
    }

    ///>initial codes
    if ($(window).width() < 992) {
        mobileFilters();
        magnificLightbox();
    }

    this.checkProgress();
    if (!isProgressFinished) {
        intervalId = setInterval(this.checkProgress, delay);
        progressFinished = setInterval(this.progressFinished, waitTime);
    }

    dataActive.hooks.register('changeCurrency', function (currency) {
        if (!currencyChangeAlert()) {
            return;
        }
        if (!$.isEmptyObject(currency)) {
            dataActive.updateConfig('currentCurrency', currency);
            searchBtn.click();
        }
    });
    dataActive.hooks.register('changeLanguage', function (language) {
        if (languageChangeAlert()) {
            return language;
        }
    });

    dataActive.hooks.register('beforePageRoute', function (configs) {
        me.kill();
        return configs;
    });
}