function VisaSearchForm($form) {
    const visaTab = $form.closest('[data-process="Banner"]').find('#visa-tab')
    dataActive.hooks.clear('afterAjaxError');
    var searchButton = $form.find('.service-search-btn');
    var me = this;
    var lang = dataActive.location.query.lang.toLowerCase();
    function getFields() {
        var data = {};
        var visaSearchDetails = {};
        $form.find("[name]").each(function (index, element) {
            var $element = $(element);
            var elementName = $element.attr('name');
            data[elementName] = visaSearchDetails[elementName] = $element.val();         
        });
        setStorage("visaSearchDetails", visaSearchDetails);
        return data;
    }

    function success(data) {
        var sessionId = data.sessionId || "";
        // lug.info("sessionId", { message: data });
        if (String(sessionId).length > 0) {
            var visaSearchDetails = getStorage("visaSearchDetails");
            visaSearchDetails["sessionId"] = sessionId;
            setStorage("visaSearchDetails", visaSearchDetails);
            dataActive.pageRoute("visaResult", data);
        } else {
            dataActive.showErrors(trans("error happend . try again later."));
        }
    }

    var loginInformation = getStorage("loginInformation");
    if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
        var headers = { 'Authorization': 'Bearer ' + loginInformation.token };
    }

    this.ajaxParams = function () {
        return {
            url: dataActive.location.marketplace + "/v1/search/visa",
            method: "post",
            headers: headers || {},
            data: getFields(),
            success: success,
            error: function (data, validation) {
                me.enabelButton();
                dataActive.defaultAjaxError(data, validation);
            },
        };
    }

    this.disableButton = function () {
        searchButton.prop('disabled', true);
    }

    this.enabelButton = function () {
        searchButton.prop('disabled', false);
    }

    function getVisaCountries() {
        let countryList = $form.find('.country-list');
        let passportCountryList = $form.find('.passport-country-list');
        countryList.empty();
        passportCountryList.empty();
        countryList.append(`<option value="">${trans('Visa_Destination')}</option>`);
        passportCountryList.append(`<option value="">${trans('passport_country')}</option>`);
        ajax({
            url: dataActive.location.marketplace + '/v1/search/destinations/visa',
            method: 'get',
            headers: headers || {},
            data: {},
            success: function (data) {
                data.data.countries.forEach(element => {
                    countryList.append(`<option value="${element.id}" ${countryList.data('value') == element.id ? 'selected' : ''}> ${element[lang] || element['en']} </option>`);
                });
                data.data.passportCountries.forEach(element => {
                    passportCountryList.append(`<option value="${element.id}" ${passportCountryList.data('value') == element.id ? 'selected' : ''}> ${element[lang] || element['en']} </option>`);
                });
            }
        })
    }
    ///> init
    $form.find('.searcherIdentity').val(localStorage.getItem('searcherIdentity'));
    if (window.location.pathname === "/" || window.location.pathname === "/home") {
        visaTab.on('click', () => getVisaCountries());
    } else {
        getVisaCountries();
    }
    var selectCountry = $form.find('.select-country');
    new CustomSelect(selectCountry, { url: dataActive.location.marketplace + '/v1/search/countries' + '?lang=' + dataActive.location.query.lang.toLowerCase(), message: 'Search a country' });
}