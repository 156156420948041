function hotelSearchForm($form) {

    dataActive.hooks.clear('afterAjaxError');
    var searchButton = $form.find('.service-search-btn');
    var me = this;
    function getFields() {
        var data = {}; ///> data from form (for ajax)
        $form.find("[name]").each(function (index, element) {
            var $element = $(element);
            var elementName = $element.attr('name');
            data[elementName] = $element.val();
        });
        return data;
    }

    function success(data) {
        var sessionId = data.sessionId || "";
        // lug.info("sessionId", { message: data });
        if (String(sessionId).length > 0) {
            var hotelSearchDetails = getStorage("hotelSearchDetails");
            hotelSearchDetails["sessionId"] = sessionId;
            setStorage("hotelSearchDetails", hotelSearchDetails);
            dataActive.pageRoute("hotelResult", data);
        } else {
            dataActive.showErrors(trans("error happend . try again later."));
        }
    }

    var loginInformation = getStorage("loginInformation");
    if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
        var headers = { 'Authorization': 'Bearer ' + loginInformation.token };
    }

    this.ajaxParams = function () {
        return {
            url: dataActive.location.marketplace + "/v1/search/hotel",
            method: "post",
            headers: headers || {},
            data: getFields(),
            success: success,
            error: function (data, validation) {
                me.enabelButton();
                dataActive.defaultAjaxError(data, validation);
            },
        };
    }

    this.disableButton = function () {
        searchButton.prop('disabled', true);
    }

    this.enabelButton = function () {
        searchButton.prop('disabled', false);
    }

    ///> init
    HotelQuantitySelector($form.find(".quantity-selector-input").data("initial"));
    $form.find('.searcherIdentity').val(localStorage.getItem('searcherIdentity'));
    $form.find('.datePickerStart').change(function () {
        $form.find('.datePickerEnd').val($(this).val());
        setTimeout(() => { $form.find('.datePickerEnd').click() }, 100);
    });

    $($form.find('.lin-people')).on('click', function () {
        $(this).siblings().click();
    })
    var fliterButton = $form.closest('.theme-hero-area').next().find('#mobileFilters');
    var selectCity = $form.find('.select-city');
    var selectCountry = $form.find('.select-country');
    new CustomSelect(selectCity, { url: dataActive.location.marketplace + '/v1/search/cities' + '?lang=' + dataActive.location.query.lang.toLowerCase(), message: 'Search a city' });
    new CustomSelect(selectCountry, { url: dataActive.location.marketplace + '/v1/search/countries' + '?lang=' + dataActive.location.query.lang.toLowerCase(), message: 'Search a country' });

    var datepicker = $form.find('.datepicker-input');
    ChooseCalendar(datepicker, fliterButton, true);
}