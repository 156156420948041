function SendPasswordResetLinkForm($form) {
    dataActive.hooks.clear('afterAjaxError');
    var me = this;
    var resetButton = $form.find('.reset-btn');
    function getFields() {
        var data = {};
        $form.find("[name]").each(function (index, element) {
            var $element = $(element);
            var elementName = $element.attr('name');
            data[elementName] = $element.val();
        });
        return data;
    }

    function success(data) {
        if (data.status == true) {
            $form.parent().parent().append(`<div>${data.message}</div>`)
            $form.parent().remove();
        }
    }
    this.ajaxParams = function () {
        return {
            url: dataActive.location.marketplace + "/v1/send/reset/password/link",
            method: "post",
            data: getFields(),
            success: success,
            error: function (data, validation) {
                me.enabelButton();
                dataActive.defaultAjaxError(data, validation);
            },
        };
    }

    this.disableButton = function () {
        resetButton.prop('disabled', true);
    }

    this.enabelButton = function () {
        resetButton.prop('disabled', false);
    }
}