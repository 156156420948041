function PackageResultSearchFormProcess(handler) {
    var $searchForm = handler.domNode.find(".package-result-search-form");
    var $searchArea = $searchForm.parent();
    var $editSearch = handler.domNode.find('.edit-search');
    var searchDetail = {};
    ajax({
        url: dataActive.location.marketplace + "/v1/search/info",
        method: 'GET',
        async: false,
        data: {
            sessionId: dataActive.location.query.sessionId
        },
        success: function (data) {
            searchDetail = data;
        }
    });
    $searchForm.append(view("package/package-result-search-form", { searchDetail: searchDetail }));
    $editSearch.click(function (e) {
        $element = $(e.target);
        if ($searchArea.hasClass('_mob-h')) {
            $searchArea.removeClass('_mob-h')
        } else {
            $searchArea.addClass('_mob-h')
        }

    });
    handler.domNode.find(".datePickerStartPackage").val(searchDetail.month);
    handler.domNode.find(".start-date").val(searchDetail.startDate);
    handler.domNode.find(".end-date").val(searchDetail.endDate);
}