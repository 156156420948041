function PackageResultProcess(handler) {
    this.progressUrl = dataActive.location.marketplace + "/v1/search/progress";
    this.resultUrl = dataActive.location.marketplace + "/v1/search/results";
    this.currentCurrency;
    var intervalId;
    var progressFinished;
    var isProgressFinished = false;
    var delay = 3000;
    var me = this;
    var waitTime = 60000;
    var $resultItem = handler.domNode.find(".package-item");
    var $moreResult = handler.domNode.find(".more-result");
    var $waiting = handler.domNode.find(".search-loader");
    var $filters = handler.domNode.find(".package-filters");
    var $filtersForm;
    var $pageNumber;
    var messageCode = "t<wt";
    var searchBtn = handler.domNode.prev().find('.service-search-btn');
    var lang = dataActive.location.query.lang;
    var headers = {};
    var loginInformation = getStorage("loginInformation");
    if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
       headers = { 'Authorization': 'Bearer ' + loginInformation.token };
    }

    searchBtn.prop('disabled', true);
    dataActive.hooks.register('afterAjaxError', this.afterAjaxError);

    this.checkProgress = function () {
        ajax({
            url: me.progressUrl,
            method: "get",
            data: {
                sessionId: dataActive.location.query.sessionId
            },
            headers: headers || {},
            success: function (data) {
                if (data.percent == 100) {
                    me.searchResult(messageCode + ",p=100");
                    isProgressFinished = true;
                }
            },
        });
    }

    this.progressFinished = function () {
        messageCode = "t>wt";
        if (isProgressFinished == false) {
            ajax({
                url: me.progressUrl,
                method: "get",
                data: {
                    sessionId: dataActive.location.query.sessionId
                },
                headers: headers || {},
                success: function (data) {
                    if (data.percent == 0) {
                        me.manageResults(messageCode + ",p=0")
                    } else if (data.percent < 100) {
                        me.searchResult(messageCode + ",p<100");
                    } else {
                        me.searchResult(messageCode + ",p=100");
                    }
                },
            });
        }
    }

    this.searchResult = function (latestMessageCode) {
        ajax({
            url: me.resultUrl,
            method: "get",
            data: {
                sessionId: dataActive.location.query.sessionId
            },
            headers: headers || {},
            success: function (data) {
                searchBtn.prop('disabled', false);
                me.currentCurrency = data.queryParams['marketplace_currency'].abb;
                if (data.data.length == 0) {
                    me.manageResults(latestMessageCode + ",d=0");
                    return;
                }
                me.manageResults(latestMessageCode + ",d!=0", data);
                messageCode = "t>wt,loaded";
            },
        })
    }

    this.moreResult = function () {
        $moreResult.hide();
        $waiting.show();
        $pageNumber.val(parseInt($moreResult.data('currentPage')) + 1);
        ajax({
            url: me.resultUrl,
            method: "get",
            data: $filtersForm.serialize(),
            headers: headers || {},
            success: function (data) {
                me.mapContent(data.data);
                $moreResult.data('currentPage', data.pages.page);
                if ($moreResult.data('pageCount') > parseInt(data.pages.page)) {
                    $moreResult.show();
                }
            },
        })
    }

    this.mapFilters = function (filters) {
        $filters.empty();
        $filters.append(view("package/package-filters", { filters: filters }));
        checkboxes();

        $filtersForm = $filters.find('.package-filter-form');
        $pageNumber = $filtersForm.find(".page-number");
    }

    this.configFilters = function (filters, pages) {
        $filters.find(".apply-filter").click(function () {
            me.applyFilters();
            $filters.next().click();
        });
        $filters.find(".reset-filter").click(function () {
            $filtersForm[0].reset();
            $filters.empty();
            me.mapFilters(filters);
            me.configFilters(filters, pages);
            me.applyFilters();
            $filters.next().click();
        });
        $filters.find(".session-id").val(dataActive.location.query.sessionId);
        $pageNumber.val(1);
        this.loadMore(pages);
    }

    this.loadMore = function (pages) {
        if ($moreResult.data('pageCount') == undefined) {
            $moreResult.click(me.moreResult)
        }
        $moreResult.data('pageCount', pages.pageCount);
        $moreResult.data('currentPage', pages.page);
        if (pages.pageCount > parseInt(pages.page)) {
            $moreResult.show();
        } else {
            $moreResult.hide();
        }
    }

    this.applyFilters = function () {
        $resultItem.empty();
        $moreResult.hide();
        $waiting.show();
        $pageNumber.val(1);
        ajax({
            url: me.resultUrl,
            method: "get",
            data: $filtersForm.serialize(),
            headers: headers || {},
            success: function (data) {
                me.mapContent(data.data);
                $pageNumber.val(2);
                me.loadMore(data.pages);
            },
        })
    }

    this.mapContent = function (data) {
        $waiting.hide();
        data.forEach((item, indexItem) => {
            var travelStyle = item.serviceInfo.package.travelStyle;
            var travelStyleItem = "";
            var availibilteyItem = "";
            for (var i in travelStyle) {
                travelStyleItem += `<li class="_ml-5"><i class="fa fa-check"></i> ${trans(travelStyle[i])} </li>`;
            }
            item.serviceInfo.availabilities.forEach((item, index) => {
                var now = dayjs(new Date()).format('YYYY-MM-DD'); //todays date
                var end = dayjs(item.from); // another date
                var days = end.diff(now, 'day');
                var month = end.diff(now, 'month');
                if (index < 3) {
                    availibilteyItem += `<div><div class="">${trans('next')} <span class="custom-font _mb-5">${((days < 30) ? days + "  " + trans('days') : month + "  " + trans('month'))}</span></div><div class="">${DateFormat(item.from, 'MD/')}</div></div>`;
                }
            })
            var $packageItem = $(view("package/package-item", { item: item, travelStyleItem: travelStyleItem, availibilteyItem: availibilteyItem, indexItem: indexItem, lang }));
            $resultItem.append($packageItem);
            me.viewAvailabilites($packageItem);
        });

    }

    this.viewAvailabilites = function (packageItem) {
        var availabilitesSection = packageItem.find('.availabilites-section');
        var waitingMessage = packageItem.find('.pls-wati');
        packageItem.find('.package-availabilites').click(function () {
            var arrow = packageItem.find('.angle-arrow');
            arrow.empty();
            var collapseSection = packageItem.find('.collapse');
            if (collapseSection.hasClass('in')) {
                arrow.append('<i class="fa fa-chevron-down" aria-hidden="true"></i>');
            } else {
                arrow.append('<i class="fa fa-chevron-up" aria-hidden="true"></i>');
            }
            if (availabilitesSection.children().length == 0) {
                ajax({
                    url: dataActive.location.marketplace + "/v1/profile/package",
                    method: "get",
                    data: {
                        referenceId: $(this).data('referenceid'),
                    },
                    success: function (data) {
                        // lug.info("package page found");
                        me.mapAvailabilites(data.data, availabilitesSection);
                        waitingMessage.hide();

                    },
                });
            }
        });
    }

    this.mapAvailabilites = function (data, availabilitesSection) {
        var bookingModal = handler.domNode.find('.package-booking-modal');
        var form = bookingModal.find('form');
        var availableRoomsSection = bookingModal.find('.available-rooms');
        form.find(".reference-id").val(data.packageReferenceId);
        data.serviceInfo.availabilities.forEach((item, index) => {
            if (item.capacity > 10) {
                capacity = "10+" + trans('seats_left');
            } else {
                capacity = item.capacity + trans('seats_left');
            }
            var $view = $(view("package/availabilities", { data: data, item: item, capacity: capacity, indexOfAvailability: index }));
            var instanseButton = $view.find(".instant-book");
            if (item.capacity == 0) {
                instanseButton.prop('disabled', true);
                instanseButton.text(trans('Full'));
            }
            instanseButton.click(function () {
                form.find('.package-price-details').empty();
                form.find(".total-price").text(0);
                form.find(".currency-abbreviation").text(me.currentCurrency);
                var availability = index;
                form.find(".availability").val(availability);
                form.find(".availability-capacity").val(item.capacity);
                var counter = 0;
                var roomtype = '';
                let roomCounter = 0;
                let firstRoomAdultPrice = 0;
                let firstRoomChildPrice = 0;
                let firstRoomChildWOBedPrice = 0;
                let firstRoomInfantPrice = 0;
                for (var room in item.rooms) {
                    let showPriceRibbon = true;
                    adultPrice = data.priceInfo.availabilities[availability]['rooms'][room]['payable']['adult'];
                    childPrice = data.priceInfo.availabilities[availability]['rooms'][room]['payable']['child'];
                    childWOBedPrice = data.priceInfo.availabilities[availability]['rooms'][room]['payable']['childWOBed'];
                    infantPrice = data.priceInfo.availabilities[availability]['rooms'][room]['payable']['infant'];
                    if (roomCounter == 0) {
                        firstRoomAdultPrice = adultPrice;
                        firstRoomChildPrice = childPrice;
                        firstRoomChildWOBedPrice = childWOBedPrice;
                        firstRoomInfantPrice = infantPrice;
                    }
                    if (roomCounter != 0 && adultPrice == firstRoomAdultPrice && childWOBedPrice == firstRoomChildWOBedPrice && childPrice == firstRoomChildPrice && infantPrice == firstRoomInfantPrice) {
                        showPriceRibbon = false;
                    }
                    roomtype += view('package/availability-room', { roomName: room, roomDetails: item.rooms[room], data: data, availability: availability, showPriceRibbon });
                    roomCounter += 1;
                }
                availableRoomsSection.html(roomtype);
                handler.domNode.find('.add-availabilty-room').click(function () {
                    let $this = $(this);
                    let room = $this.data('room');
                    let roomsSection = $this.parent().next();
                    let roomtype = $this.data('roomtype');
                    counter += 1;
                    if (room.quantity > roomsSection.children().length) {
                        roomsSection.append(view('package/availability-room-item', { room: room, counter: counter, roomtype: roomtype, data: data, availability: availability }));
                        me.calculate(form);
                        me.showPriceDetails(form, data);
                        form.find('.extra-price').click(function () {
                            let $extraPrice = $(this);
                            $extraPrice.closest('.extra-price-section').find('input').removeClass('calc-price');
                            $extraPrice.children().addClass('calc-price');
                            me.calculate(form);
                            me.showPriceDetails(form, data);
                        });
                        form.find('select').change(function () {
                            me.calculate(form);
                            me.showPriceDetails(form, data);
                        });
                    }
                    if (room.quantity == 'unlimited') {
                        roomsSection.append(view('package/availability-room-item', { room: room, counter: counter, roomtype: roomtype, data: data, availability: availability }));
                        me.calculate(form);
                        me.showPriceDetails(form, data);
                        form.find('.extra-price').click(function () {
                            let $extraPrice = $(this);
                            $extraPrice.closest('.extra-price-section').find('input').removeClass('calc-price');
                            $extraPrice.children().addClass('calc-price');
                            me.calculate(form);
                            me.showPriceDetails(form, data);
                        });
                        form.find('select').change(function () {
                            me.calculate(form);
                            me.showPriceDetails(form, data);
                        });

                    }
                    roomsSection.find('.remove-room').click(function (e) {
                        $(this).parent().parent().parent().remove();
                        me.calculate(form);
                        me.showPriceDetails(form, data);
                    });
                });
                bookingModal.find('.infant-age-range').text(item.ageRange.infant);
                bookingModal.find('.child-age-range').text(item.ageRange.child);
                bookingModal.find('.child-wo-bed-age-range').text(item.ageRange.childWOBed);
                bookingModal.find('.adult-age-range').text(item.ageRange.adult);
                bookingModal.find('a').magnificPopup('open');
                let servicesSection = form.find('.extra-services');
                servicesSection.empty();
                if (!$.isEmptyObject(item.services)) {
                    servicesSection.append(view("package/services"))
                    for (let service in item.services) {
                        form.find('.extra-service-item').append(view("package/extra-service-item", { service: item.services[service], indexOfService: service, availability, data }))
                    }
                    form.find('select').change(function () {
                        me.calculate(form);
                    });
                }

            });
            availabilitesSection.append($view);
        });

        $('.package-booking-modal a').magnificPopup({
            type: 'inline',
            fixedContentPos: true
        });

        form.find('.package-book-now').click(me.booking);
    }

    this.calculate = function (form) {
        var totalPrice = 0
        form.find('.calc-price').each((index, item) => {
            var $item = $(item);
            if ($item.prop("tagName") == 'SELECT') {
                $item.data('price', $item.find(":selected").data('price'));
            }
            totalPrice += $item.data('price');
        });
        totalPrice = currencyFormatter(totalPrice);
        form.find(".total-price").text(totalPrice);
        form.find(".currency-abbreviation").text(me.currentCurrency);
    }

    this.showPriceDetails = function (form, data) {
        var priceDetailsSection = form.find('.package-price-details');
        priceDetailsSection.empty();
        var rooms = form.find('.room-item');
        if (rooms.length > 0) {
            priceDetailsSection.append(view('package/price-details-table'));
            rooms.each((index, element) => {
                var $element = $(element);
                var adults = 0;
                var childern = 0;
                var childrenWoBed = 0;
                var infants = 0;
                $element.find('.calc-price').each((index, item) => {
                    var $item = $(item);
                    if ($item.prop("tagName") == 'SELECT') {
                        $item.data('value', $item.val());
                        $item.data('bed', $item.find(":selected").data('bed'));
                    }
                    var passengerType = $item.data('bed');
                    switch (passengerType) {
                        case 'adult':
                            adults += parseInt($item.data('value'))
                            break;
                        case 'child':
                            childern += parseInt($item.data('value'))
                            break;
                        case 'childWoBed':
                            childrenWoBed += parseInt($item.data('value'))
                            break;
                        case 'infant':
                            infants += parseInt($item.data('value'))
                            break;
                        default:
                            break;
                    }
                });
                var roomtype = $element.data('roomtype');
                var availability = $element.data('availability');
                priceDetailsSection.find('.room-price-details').append(view('package/room-price-details', { roomtype: roomtype, adults: adults, childern: childern, childrenWoBed: childrenWoBed, infants: infants, availability: availability, data: data }));
                $element.find('.number-of-adults').val(adults);
                $element.find('.number-of-children').val(childern);
            });
        }
    }


    this.booking = function () {
        var $this = $(this);
        $this.attr('disabled', true);
        $this.html('<i class="fa fa-circle-o-notch fa-spin buttonload "></i>');
        dataActive.hooks.register('afterAjaxError', () => dataActive.pageReload());
        var form = $this.parent();
        let formData = me.objectifyForm(form.serializeArray());
        let capacity = formData.capacity;
        passengerCount = 0;
        for (const key in formData) {
            if (key.includes('[adults]') || key.includes('[childWOBed]') || key.includes('[children]')) {
                passengerCount += parseInt(formData[key]);
            }
        }
        if (capacity < passengerCount) {
            swal({
                text: trans('The remaining capacity is less than the number you selected.'),
                dangerMode: true,
                icon: "error",
            });
            $this.attr('disabled', false);
            $this.html(trans('Book_Now'));
            return;
        }
        if ((capacity <= 6) && (capacity - passengerCount) == 1) {
            swal({
                text: trans('You cannot book this item with this number of passengers'),
                dangerMode: true,
                icon: "error",
            });
            $this.attr('disabled', false);
            $this.html(trans('Book_Now'));
            return;
        }
        var loginInformation = getStorage("loginInformation");
        if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
            var headers = { 'Authorization': 'Bearer ' + loginInformation.token };
        }
        ajax({
            url: dataActive.location.marketplace + "/v1/book/package/create",
            method: "post",
            headers: headers || {},
            data: form.serialize(),
            success: function (data) {
                $('.package-booking-modal').magnificPopup('close');
                dataActive.pageRoute("passengerInfo", { referenceId: data.bookingReferenceId, type: "package" });
            },
            error: function (data, validation) {
                dataActive.defaultAjaxError(data, validation);
                $this.attr('disabled', false);
                $this.html(trans("Book_Now"));
                $('.package-booking-modal').magnificPopup('close');
            }
        });
    }

    this.objectifyForm = function (formArray) {
        var returnArray = {};
        for (var i = 0; i < formArray.length; i++) {
            returnArray[formArray[i]['name']] = formArray[i]['value'];
        }
        return returnArray;
    }

    this.manageResults = function (latestMessageCode, data) {
        switch (latestMessageCode) {
            case "t<wt,p=100,d=0":
                me.showNotFoundError();
                me.kill();
                break;
            case "t<wt,p=100,d!=0":
                me.mapData(data);
                me.kill();
                break;
            case "t>wt,p=0":
            case "t>wt,p<100,d=0":
                me.showAlertQuestion("No Response!", "Wait More", "Try.Again!");
                break;
            case "t>wt,p<100,d!=0":
                me.mapData(data);
                break;
            case "t>wt,p=100,d=0":
                me.showNotFoundError();
                me.kill();
                break;
            case "t>wt,p=100,d!=0":
                me.mapData(data);
                me.kill();
                break;
            case "t>wt,loaded,p=100,d!=0":
                me.alertReloade("More result found. Do  you wish to reload now?", "N0", "Yes");
                break;
            default:
                me.showNotFoundError();
                me.kill();
                break;
        }


    }

    this.showNotFoundError = function () {
        $waiting.hide();
        handler.domNode.find('.show-not-found-message').show();
    }

    this.showAlertQuestion = function (text, cancelText, confirmText) {
        swal({
            text: trans(text),
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: trans(cancelText),
                    value: null,
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                confirm: {
                    text: trans(confirmText),
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true
                }
            }
        })
            .then((response) => {
                if (response == true) {
                    searchBtn.click();
                }
            });
    }

    this.alertReloade = function (text, cancelText, confirmText) {
        swal({
            text: trans(text),
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: trans(cancelText),
                    value: null,
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                confirm: {
                    text: trans(confirmText),
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true
                }
            }
        })
            .then((response) => {
                if (response == true) {
                    me.kill();
                    ajax({
                        url: dataActive.location.marketplace + "/v1/search/refresh",
                        method: "get",
                        data: {
                            sessionId: dataActive.location.query.sessionId
                        },
                        success: function (filters) {
                            $resultItem.empty();
                            me.mapContent(data.data, data.pages);
                            me.mapFilters(filters);
                            me.configFilters(filters, data.pages);

                        }
                    })
                }
            });
    }

    this.mapData = function (data) {
        me.mapContent(data.data, data.pages);
        me.mapFilters(data.filters);
        me.configFilters(data.filters, data.pages);
    }

    this.afterAjaxError = function () {
        dataActive.pageRoute('index');
    };

    this.kill = function () {
        clearInterval(intervalId);
        clearInterval(progressFinished);
    }

    if ($(window).width() > 992) {
        dataActive.hooks.register('afterPageRoute', function () {
            $('.sticky-col').stick_in_parent({
                parent: $('#sticky-parent')
            });
        });
    }

    ///>initial codes
    if ($(window).width() < 992) {
        mobileFilters();
        magnificLightbox();
    }

    this.checkProgress();
    if (!isProgressFinished) {
        intervalId = setInterval(this.checkProgress, delay);
        progressFinished = setInterval(this.progressFinished, waitTime);
    }

    dataActive.hooks.register('changeCurrency', function (currency) {
        if (!currencyChangeAlert()) {
            return;
        }
        if (!$.isEmptyObject(currency)) {
            dataActive.updateConfig('currentCurrency', currency);
            searchBtn.click();
        }
    });
    dataActive.hooks.register('changeLanguage', function (language) {
        if (languageChangeAlert()) {
            return language;
        }
    });
    dataActive.hooks.register('beforePageRoute', function (configs) {
        me.kill();
        return configs;
    });
}