function MyProfileProcess(handler) {
    var me = this;
    var data = {};
    this.showResult = function () {
        ajax({
            url: dataActive.location.marketplace + "/v1/myprofile",
            method: "get",
            headers: { 'Authorization': 'Bearer ' + getStorage("loginInformation").token },
            data: data,
            success: function (data) {
                me.mapContent(data)
            },
        });
    }
    this.mapContent = function (data) {
        handler.domNode.find('.user-information').append(view("partials/user-information-in-my-profile", {data}))
    }
    this.init = function () {
        this.showResult();
    }
    this.init();
}